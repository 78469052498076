@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Inter', sans-serif;
  transition: background-color 0.8s, color 0.8s;
  transition-timing-function: ease-in-out;
}

.container {
  position: relative;
  text-align: center;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 80px;
  height: 30px;
  background-color: var(--border-color);
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.8s;
  transition-timing-function: ease-in-out;
}

.toggle-indicator {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: var(--bg-color);
  border-radius: 50%;
  transition: transform 0.8s ease-in-out, background-color 0.8s ease-in-out;
}

.switch-container.light {
  background-color: var(--border-color);
}

.switch-container.light .toggle-indicator {
  left: 2px;
  background-color: #000;
}

.switch-container.dark {
  background-color: var(--border-color);
}

.switch-container.dark .toggle-indicator {
  left: calc(100% - 28px);
  background-color: #fff;
}

.toggle-button {
  display: none; 
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.loading-bar {
  width: 200px;
  height: 10px;
  background-color: #000000;
  margin-bottom: 20px;
}

.percentage-loader {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 200px;
  }
}

.loading-bar.active {
  animation: loading 3s linear forwards;
}